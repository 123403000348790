.articleCard {
	display: flex;
	flex-direction: column;
	max-width: calc(1019px / 3);
	width: 100%;
	max-height: 571px;
	row-gap: 20px;
	cursor: pointer;
	.picture {
		width: 100%;
		object-fit: cover;
		height: 319px;
		min-height: 319px;
	}
	.header {
		.title {
			margin: 0;
			font-size: 28px;
			font-weight: bold;
		}
		.author {
			margin: 0;
			font-size: 28px;
		}
		.date {
			margin: 0;
		}
	}
	.description {
		margin: 0;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
