@import '../../../styles/colors';

.navbar {
	.menuIcon {
		height: 28px;
		min-width: 28px;
		cursor: pointer;
	}
	.menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		transform: translateX(100%);
		transition: transform 0.3s ease-out;
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: $black;
		z-index: 200;
		padding: 30px 36px;
		.crossIcon {
			align-self: flex-end;
			min-height: 32px;
			width: 32px;
			cursor: pointer;
			padding: 5px;
		}
		.linkGroup {
			display: flex;
			flex: 1;
			width: fit-content;
			// justify-content: center;
			justify-content: flex-start;
			padding-top: 50px;
			flex-direction: column;
			gap: 40px;
		}
		.navlink {
			display: flex;
			align-items: center;
			text-decoration: none;
			.navlinkTitle {
				font-family: 'Alef', sans-serif;
				font-size: calc(1 * (1.5vh + 1vw));
			}
			&:hover {
				text-decoration: underline;
				text-decoration-color: $white;
				-moz-text-decoration-color: $white;
				text-decoration-thickness: 2px;
				text-underline-offset: 4px;
			}
			&.active {
				text-decoration: underline;
				text-decoration-color: $white;
				-moz-text-decoration-color: $white;
				text-decoration-thickness: 2px;
				text-underline-offset: 4px;
			}
		}
		&.active {
			transform: translateX(0) !important;
		}
	}
	@media screen and (min-width: 971px) {
		display: none;
	}
}
