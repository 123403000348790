@import '../../styles/colors';

.pictureCard {
	display: flex;
	&:hover .hover {
		display: flex;
	}
	.hover {
		height: 100%;
		width: 100%;
		padding: 20px;
		position: absolute;
		display: none;
		flex-direction: column;
		justify-content: flex-end;
		box-shadow: inset 0 0 0 2000px $blur;
		.title {
			font-size: 28px;
			font-weight: bold;
			margin: 0;
		}
		.desc {
			font-size: 21px;
			overflow: scroll;
			margin: 0;
			-webkit-overflow-scrolling: touch;
		}
	}
	.picture {
		width: 100%;
	}
}
