.socialNetwork {
	display: flex;
	text-decoration: none;
	align-items: center;
	column-gap: 30px;
	width: 100%;
	.icon {
		width: 60px;
		height: 60px;
		@media screen and (max-width: 970px) {
			width: calc(1.5 * (2vh + 2vw));
			height: calc(1.5 * (2vh + 2vw));
		}
	}
	.link {
		font-size: 28px;
		color: black;
		@media screen and (max-width: 970px) {
			font-size: calc(1 * (1.5vh + 2vw));
		}
	}
}
