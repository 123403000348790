.link {
	display: flex;
	gap: 50px;
	margin-top: 50px;
	width: 100%;
	text-decoration: none;
	.picture {
		width: 30%;
	}
}
