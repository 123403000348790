@import '../../styles/colors';

.header {
	width: 100vw;
	height: 678px;
	max-height: 678px;
	.content {
		width: calc(100% - 50px);
		max-width: 1119px;
		height: 100%;
		margin: auto;
		padding-bottom: 100px;
		display: flex;
		align-items: flex-end;
		column-gap: 30px;
		z-index: 1;
		@media screen and (max-width: 970px) {
			flex-direction: column;
			align-items: flex-start;
			row-gap: 30px;
			padding-top: 50px;
			padding-botom: 50px;
		}
		.title {
			writing-mode: tb-rl;
			font-size: 55px;
			transform: rotate(-180deg);
			margin: 0;
			font-weight: 400;
		}
		.titlePicture {
			width: 110px;
			margin-left: auto;
			margin-right: auto;
			@media screen and (max-width: 970px) {
				display: none;
			}
		}
		.horizontalTitlePicture {
			height: calc(3 * (2vh + 1vw));
			margin-left: 0;
			@media screen and (min-width: 971px) {
				display: none;
			}
		}
		.contentPicture {
			object-fit: contain;
			width: 800px;
		}
		.description {
			font-size: 28px;
			margin: 0;
			max-width: 894px;
			@media screen and (max-width: 970px) {
				font-size: calc(1 * (2vh + 1.2vw));
			}
		}
		.socialNetworks {
			width: 100%;
		}
	}
}

.blur {
	box-shadow: inset 0 0 0 2000px $blur;
}
