.container {
	display: flex;
	flex-wrap: wrap;
	gap: 50px;
	align-items: flex-end;
}

.container > * {
	flex-basis: calc(50% - 25px);
}
