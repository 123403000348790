@import '../../styles/colors';

.section {
	display: flex;
	flex-direction: column;
	border-top: 2px solid $white;
	.header {
		display: flex;
		align-items: center;
		cursor: pointer;
		.button {
			cursor: pointer;
			width: 58px;
			object-fit: contain;
			padding-top: 6px;
		}
		.title {
			font-weight: 300;
			font-size: 28px;
		}
	}
}
