.section {
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	.title {
		margin: 0;
	}
	.date {
		margin: 0;
	}
	.content {
		white-space: pre-line;
	}
}
