@import '../../../styles/colors';

.navbar {
	width: 100%;
	display: flex;
	column-gap: 40px;
	justify-content: flex-end;
	.navbarIcon {
		object-fit: contain;
		width: 20px;
		cursor: pointer;
	}
	.navlink {
		display: flex;
		align-items: center;
		text-decoration: none;
		.navlinkTitle {
			font-family: 'Alef', sans-serif;
			font-size: 14px;
		}
		&:hover {
			text-decoration: underline;
			text-decoration-color: $white;
			-moz-text-decoration-color: $white;
			text-decoration-thickness: 2px;
			text-underline-offset: 4px;
		}
		&.active {
			text-decoration: underline;
			text-decoration-color: $white;
			-moz-text-decoration-color: $white;
			text-decoration-thickness: 2px;
			text-underline-offset: 4px;
		}
		@media screen and (max-width: 970px) {
			display: none;
		}
	}
}
