.column {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	height: fit-content;
	padding-bottom: 10px;
	border-bottom: 2px solid white;
}
.wide {
	--parentWidth: 34.4%;
	width: var(--parentWidth);
	max-width: 384.5px;
}

.thin {
	--parentWidth: 29.5%;
	width: var(--parentWidth);
	max-width: 330px;
}
