.container {
	display: flex;
	margin-top: 50px;
	@media screen and (max-width: 970px) {
		flex-direction: column-reverse;
	}
	padding-bottom: 30px;
	border-bottom: 2px solid white;
}
.biography {
	max-height: 750px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	p {
		white-space: pre-line;
	}
}
.skillsList {
	display: flex;
	white-space: nowrap;
	flex-direction: column;
	align-items: flex-end;
	margin-left: 100px;
	font-size: 55px;
	font-weight: 300;
	a {
		margin: 0;
		text-decoration: none;
	}
	@media screen and (max-width: 970px) {
		font-size: calc(1.6 * (1.5vh + 1.8vw));
		width: 100%;
		margin: 0 0 50px 0;
		column-gap: 10px;
		flex-direction: row;
		flex-wrap: wrap;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
