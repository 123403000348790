.container {
	display: flex;
	flex-direction: column;
	.title {
		margin: 0;
		cursor: pointer;
	}
	.date {
		margin: 0;
	}
}
