.container {
	display: flex;
	margin-top: 50px;
	@media screen and (max-width: 970px) {
		flex-direction: column-reverse;
	}
}
.eventsList {
	max-height: 525px;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
}
.datesList {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: 100px;
	font-size: 55px;
	font-weight: 300;
	a {
		margin: 0;
		opacity: 0.4;
		text-decoration: none;
	}
	.active {
		opacity: 1;
	}
	@media screen and (max-width: 970px) {
		width: 100%;
		margin: 0 0 50px 0;
		font-size: calc(1.8 * (1.5vh + 1.8vw));
		column-gap: 10px;
		flex-direction: row;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
