@import '../../styles/colors';

.wrapper {
	width: 100%;
	background-color: $black;
	z-index: 9999;
	position: sticky;
	top: 0;
}
.menu {
	display: flex;
	margin: auto;
	width: calc(100% - 50px);
	max-width: 1119px;
	height: 92px;
	justify-content: space-between;
	align-items: center;
}
.logo {
	width: 136px;
	object-fit: contain;
	height: 100%;
}
