.article {
	display: flex;
	flex-direction: column;
	width: 100%;
	row-gap: 20px;
	.picture {
		width: 100%;
		object-fit: cover;
		height: 612px;
		min-height: 612px;
	}
	.header {
		.title {
			margin: 0;
			font-size: 28px;
			font-weight: bold;
		}
		.author {
			margin: 0;
			font-size: 28px;
		}
		.date {
			margin: 0;
		}
	}
}
