.container {
	display: flex;
	width: 100%;
	margin-top: 50px;
	height: 382px;
}
.slide {
	max-width: 491px !important;
	width: 100% !important;
	height: 100% !important;
}
.button {
	cursor: pointer;
	width: 58px;
	object-fit: contain;
	@media screen and (max-width: 970px) {
		display: none;
	}
}
